import React from 'react'

import Helmet from 'react-helmet'

import styled, { createGlobalStyle } from 'styled-components'

import '../third-party-css/reset.css'
import '../third-party-css/carousel.css'

import Nav from './Nav'
import SocialMediaBar from './SocialMediaBar'
import MobileSocialMediaBar from './MobileSocialMediaBar'

export default ({ children }) => (
	<LayoutContainer>
		<GlobalStyle />
		<div>
			<Helmet title={ title } meta={ meta }>
				<html lang="en" />
			</Helmet>

			{/* <SocialMediaBar /> */}

			<Nav />

			<Main>{ children }</Main>
		</div>

		<SiteFooter>
			{/* <MobileSocialMediaBar /> */}
			<div>Copyright Ⓒ 1990 - { new Date().getFullYear() }. All rights reserved</div>
		</SiteFooter>
	</LayoutContainer>
)

const GlobalStyle = createGlobalStyle`
	html, body, #___gatsby, #___gatsby > div {
		min-height: 100% !important;
		height: 100%;
	}

	body {
		display: grid;
		grid-template-rows: 1fr auto;
		font-size: 62.5%;
		font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
	}

	h1 h2 h3 h4 h5 {
		font-family: 'Open Sans';
	}

	img {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	p {
		font-size: 1.55em;
	}

	ul, ol {
		font-size: 1.5em;
	}

	.show-more-anchor {
		text-decoration: none;
		color: hsl(356, 70%, 53%);
	}
`
const LayoutContainer = styled.div`
	padding-top: 40px;
	height: 100%;
	min-height: 100%;
	display: grid;
	grid-template-rows: 1fr auto;
`
const Main = styled.main`
	padding: 65px 0;
	@media (max-width: 720px) {
		padding: 40px 0;
	}
`
const StickyFooter = styled.footer`
	grid-row-start: 2;
	grid-row-end: 3;
`
const SiteFooter = styled(StickyFooter)`
	text-align: center;
	font-size: 1.2em;
	padding-bottom: 10px;
`

const title = 'Ditan Wushu & Contortion - NYC'

const meta = [
	{ charset: 'utf-8' },
	{ name: 'description', content: `
		This is a family operated school. As gifted master instructors, we want to share our skills with you. 
		Wu Shu Grandmaster Edward Aguirre has been teaching Wu Shu in New York City for 38 years. My focus 
		is to make each student achieve their max potential regardless of age and size. Contortion Head Coach 
		Amarsanaa is a Mongolian A level world performer. She has a passion for teaching.
	` },
	{ name: 'keywords', content: 'NYC, New York, Wushu NYC, Wushu New York City, Wushu, Wu Shu, Contortion, Martial Arts, Kung Fu, Fitness, Working Out, Performance Art, Lossing Weight' },
]
