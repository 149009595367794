import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import styled from 'styled-components'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Container>
			{/* <SocialMediaLink
				href="https://www.facebook.com/ditanwushucontortionnyc"
				target="_blank"
				rel="noopener noreferrer">
				<Img fixed={ data.facebook.childImageSharp.fixed } />
			</SocialMediaLink> */}
			<SocialMediaLink
				href="https://www.instagram.com/ditan_official/"
				target="_blank"
				rel="noopener noreferrer">
				<Img fixed={ data.instagram.childImageSharp.fixed } />
			</SocialMediaLink>
		</Container>
	)
}

const Container = styled.div`
	visibility: hidden;
	display: none;
	@media (max-width: 1100px) {
		visibility: visible;
		display: inline-block;
	}
`
const SocialMediaLink = styled.a`
	display: inline-block;
	margin-right: 5px;
	margin-left: 5px;
`

const query = graphql`
	query {
		facebook: file(relativePath: { eq: "social/facebook_sm.png" }) {
			childImageSharp {
				fixed(width: 48, height: 48) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		},
		instagram: file(relativePath: { eq: "social/instagram_sm.png" }) {
			childImageSharp {
				fixed(width: 48, height: 48) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		}
	}
`
