import React, { useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import Container from '../components/Container'
import Layout from '../components/Layout'

const taughtForms = {
	weapons: {
		title: 'Weapons',
		forms: [
			'Nan Dao (Southern Broadsword)',
			'Nan Gun (Southern Staff)',
			'San Jie Gun (Three Section Staff)',
			'Jiu Jie Bian (Nine Section Whip Chain)',
			'Zui Jian (Drunken Sword)',
			'Chang Shui Jian (Long Tassel Sword - Women Form)',
			'Pu Dao (Long  Handle Cutter)',
			'Guang Dao (General Guang\'s Famous Weapon)',
			'Shuang Dao (Double Broadsword)',
			'Dao Shu (Broadsword)',
			'Gun Shu (Cudgel)',
			'Qiang Shu (Spear)',
			// 'Sheng Biao (Rope Dart)',
			// 'Shuang Gao (Double Hook Swords)'
		]
	},
	fists: {
		title: 'Fists',
		forms: [
			'Chang Quan (Long Fist)',
			'Nan Quan (Southern Fist)',
			'Tang Lang Quan (Mantis Fist)',
			'Ying Zhua (Eagle Claw Boxing)',
			'Zui Quan (Drunken Fist Boxing)',
			'Tong Bei (White Ape Boxing)',
			'Baji Quan (Eight Extremes Fist - Internal)',
			'Ditan Quan (Earth Boxing)'
		]
	},
	fighting: {
		title: 'Fighting Sets',
		forms: [
			'Two men armed or unarmed',
			'Spear vs Empty Hands',
			'Pu Dao vs Spear',
			'Staff vs Three Sectional Staff'
		]
	},
	defense: {
		title: 'Self Defense',
		forms: [
			'Qin\'Na - Practical joint locking',
			'Sanda - Full contact fighting',
			'Sanshou - Amateur contact fighting'
		]
	}
}

export default () => {
	const data = useStaticQuery(query)

	const formTypes = Object.keys(taughtForms)

	const [ selectedFormType, setSelectedFormType ] = useState(formTypes[0])

	return (
		<Layout>
			<Container>
				<FormSection>
					<div>
						<Header>Skills taught</Header>
						<div>
							{ formTypes.map(formType => (
								<FormCategory
									key={ formType }
									isActive={ selectedFormType === formType }
									onClick={ () => setSelectedFormType(formType) }>
									{ taughtForms[formType].title }
								</FormCategory>
							)) }
						</div>
						<Img fluid={ data.four.childImageSharp.fluid } alt="Banner" />
					</div>
					<FormListContainer>
						<FormList>
							{ taughtForms[selectedFormType].forms.map(form => (
								<li key={ form }>{ form }</li>
							)) }
						</FormList>
					</FormListContainer>
				</FormSection>
				<section>
					<Header>Empty Hand Routines</Header>
					<p>
						Chángquán (長拳 or Long Fist) refers to long-range extended Wu Shu styles like Chāquán (查拳), Huaquan (華拳), Hongquan (洪拳; "flood fist"), and 
						Shaolinquan (少林拳), but this Wu Shu form is a modernized style derived from movements of these and other traditional styles. 
						Changquan is the most widely seen of the Wu Shu forms, and includes speed, power, accuracy, and flexibility. Changquan is difficult to 
						perform, requiring great flexibility and athleticism, and is often practiced from a young age. All nandu movements must be made within 4 
						steps or it will not count for nandu points.
					</p>
					<p>
						Nanquan (南拳 or Southern Fist) refers to Wu Shu styles originating in south China (i.e., south of the Yangtze River, including Hongjiaquan (Hung Gar) (洪家拳), Cailifoquan (Choy Li Fut) (蔡李佛拳), and Yongchunquan (Wing Chun) (詠春拳). Many are known for vigorous, athletic movements with very stable, low stances and intricate hand movements. This Wu Shu form is a modern style derived from movements of these and other traditional southern styles.  Nanquan typically requires less flexibility and has fewer acrobatics than Changquan, but it also requires greater leg stability and power generation through leg and hip coordination. This event was created in 1960. All nandu movements must be made within 4 steps or it will not count for nandu points.
					</p>
					<p>
						Taijiquan (太極拳, Tai Chi Ch'uan) is a Wu Shu style mistakenly famous for slow, relaxed movements, often seen as an exercise method for the elderly, and sometimes known as "Tai chi" in Western countries to those otherwise unfamiliar with Wu Shu. This Wu Shu form (42 form) is a modern recompilation based on the Yang (楊) style of Taijiquan, but also including movements of the Chen (陳), Wu (吳), Wu (武), and Sun(孫) styles. Competitive contemporary taiji is distinct from the traditional first form for styles it draws from, in that it typically involves difficult holds, balances, jumps and jump kicks. Modern competitive taiji requires good balance, flexibility and strength. The traditional second forms however like cannon fist, are more difficult than the modern forms, but less known and usually taught to advanced students.
					</p>
				</section>
				<section>
					<Header>Short Weapons</Header>
					<p>
						Dao (刀 or broadsword) refers to any curved, one-sided sword/blade, but this Wu Shu form is a Changquan method of using a medium-sized willow-leaf-shaped dao (柳葉刀).
						Nandao (南刀 or Southern Style broadsword) refers to a form performed with a curved, one sided sword/blade based on the techniques of Nanquan. The weapon and techniques appears to be based on the butterfly swords of Yongchunquan, a well-known Southern style. In the Wu Shu form, the blade has been lengthened and changed so that only one is used (as opposed to a pair). This event was created in 1992.
						Jian (劍 or sword) refers to any double-edged straight sword/blade, but this Wu Shu form is a Changquan method of using the jian.
						Taijijian (太極劍 or Taiji sword) is an event using the jian based on traditional Taijiquan jian methods.
					</p>
				</section>
				<section>
					<Header>Long Weapons</Header>
					<p>
					Gun (棍 or cudgel) refers to a long staff (shaped from white wax wood) as tall as the wrist of a person standing with his or her arms stretched upwards, but this Wu Shu form is a Changquan method of using the white wax wood staff.
					Nangun (南棍 or Southern cudgel) is a Nanquan method of using the staff. This event was created in 1992. A Nangun should be as tall as a person holding a fist above his head. There are several basic steps and techniques that must be included in an Optional Southern cudgel event. The basic steps are bow stance, empty stance, dragon riding stance (弓步、虛步、騎龍步).[9] The basic techniques in Southern Cudgel are cleft stick, collapse stick, twisted stick, roll press stick, defend stick, hit stick, uphold stick, throw stick (劈棍、崩棍、絞棍、滾壓棍、格 棍、擊棍、頂棍、拋棍).[10]
					Qiang (槍 or spear) refers to a flexible spear with red horse hair attached to the spearhead, but this Wu Shu form is a Changquan method of using the qiang.
					</p>
				</section>
				<section>
					<Header>Other Taolu Routines</Header>
					<p>
						The majority of routines used in the sport are new, modernized recompilations of traditional routines. However, 
						routines taken directly from traditional styles, including the styles that are not part of standard events, may 
						be performed in competition, especially in China. These routines generally do not garner as many points as 
						their modern counterparts, and are performed in events separate from the compulsory routine events. Among 
						these, the more commonly seen routines include:
					</p>
					<ul>
						<li>Baguazhang (八卦掌) – Eight-Trigrams Palm</li>
						<li>Bajiquan (八極拳/八极拳) – Eight Extremes Fist/Boxing</li>
						<li>Chāquán (查拳) – Cha Fist/Boxing</li>
						<li>Chuōjiǎo (戳腳/戳脚) – Poking Feet</li>
						<li>Ditangquan (地躺拳) – Ground-Prone Fist/Boxing</li>
						<li>Fānziquán (翻子拳) – Tumbling Fist/Boxing</li>
						<li>Houquan (猴拳) – Monkey Fist/Boxing</li>
						<li>Huaquan (華拳/华拳) – Hua Fist/Boxing</li>
						<li>Nanquan (南拳) – Southern Fist</li>
						<li>Pào Chuí (炮捶) – Cannon Punch</li>
						<li>Piguaquan (劈掛拳) – Chop-Hitch Fist/Boxing</li>
						<li>Shequan (蛇拳) – Snake Fist/Boxing</li>
						<li>Tán Tuǐ (弹腿) – Spring Kick</li>
						<li>Tang Lang (螳螂拳) – Praying Mantis Fist/Boxing</li>
						<li>Tongbeiquan (通背拳) – Through-the-Back Fist/Boxing</li>
						<li>Wing Chun (詠春拳/咏春拳) – Eternal Spring</li>
						<li>Xing Yi Quan (形意拳) – Shape-Intent Fist/Boxing</li>
						<li>Ying Zhao Pai (鷹爪拳/鹰爪拳) – Eagle Claw Fist/Boxing</li>
						<li>Zui Quan (醉拳) – Drunken Fist/Boxing</li>
					</ul>
				</section>
				<section>
					<Header>Traditional Weapons Routines</Header>
					<p>There is also a traditional weapons category, which often includes the following:</p>
					<ul>
						<li>Shuangshoujian (雙手劍/双手剑) – Two-Handed Sword</li>
						<li>Jiujiebian (九節鞭/九节鞭) – Nine Section Whip</li>
						<li>Sanjiegun (三節棍/三节棍) – Three Section Staff</li>
						<li>Shengbiao (繩鏢/绳镖) – Rope Dart</li>
						<li>Dadao (大刀) – Great Sword</li>
						<li>Pudao (撲刀/扑刀) – Horse Knife</li>
						<li>Emeici (峨嵋刺) – Emei Daggers</li>
						<li>Shuangdao (雙刀/双刀) – Double Broadsword</li>
						<li>Shuanggou (雙鈎/双钩) – Double Hook-sword</li>
					</ul>
					<Header>Sanda (sparring)</Header>
					<p>
						The other major discipline of contemporary Chinese Wu Shu is 散打 Sǎndǎ, or 运动散打 (Yùndòng Sǎndǎ, Sport Free-Fighting), or 竞争散打 (Jìngzhēng Sàndǎ, 
						Competitive Free-Fighting): A modern fighting method, sport, and applicable component of Wu Shu / Kung Fu influenced by traditional Chinese Boxing, of 
						which takedowns and throws are legal in competition, as well as all other sorts of striking (use of arms and legs). Chinese wrestling methods called Shuai 
						Jiao and other Chinese grappling techniques such as Chin Na. It has all the combat aspects of Wu Shu.
						Sanda appears much like Kickboxing or Muay Thai, but includes many more grappling techniques. Sanda fighting competitions are often held alongside taolu 
						or form competitions. Sanda represents the modern development of Lei Tai contests, but with rules in place to reduce the chance of serious injury. Many 
						Chinese martial art schools teach or work within the rule sets of Sanda, working to incorporate the movements, characteristics, and theory of their style.
					</p>
					<p>
						Chinese martial artists also compete in non-Chinese or mixed combat sports, including Boxing, Kickboxing and Mixed Martial Arts. Sanda is practiced in 
						tournaments and is normally held alongside taolu events in Wu Shu competition. For safety reasons, some techniques from the self-defense form such as 
						elbow strikes, chokes, and joint locks, are not allowed during tournaments. Competitors can win by knockout or points which are earned by landing strikes 
						to the body or head, throwing an opponent, or when competition is held on a raised lei tai platform, pushing them off the platform. Fighters are only allowed 
						to clinch for a few seconds. If the clinch is not broken by the fighters, and if neither succeeds in throwing his opponent within the time limit, the referee will 
						break the clinch. In the U.S., competitions are held either in boxing rings or on the raised lei tai platform. Amateur fighters wear protective gear.
						"Amateur Sanda" allows kicks, punches and throws. A competition held in China, called the "King of Sanda", is held in a ring similar to a boxing ring in design 
						but larger in dimension. As professionals, they wear no protective gear except for gloves, cup, and mouthpiece, and "Professional Sanda" allows knee and elbow 
						strikes (including to the head) as well as kicking, punching and throwing.
					</p>
					<p>
						Some Sanda fighters have participated in fighting tournaments such as K-1, Muay Thai, Boxing and Shoot Boxing. They have had some degree of success, especially 
						in Shoot boxing competitions, which is more similar to Sanda. Due to the rules of Kickboxing competition, Sanda fighters are subjected to more limitations than 
						usual. Also notable competitors in China's mainstream Mixed Martial Arts competitions, Art of War Fighting Championship and Ranik Ultimate Fighting Federation 
						are dominantly of Wu Shu background. Sanda has been featured in many style-versus-style competitions. Muay Thai is frequently pitted against Sanda as is Karate, 
						Kickboxing, and Tae Kwon Do. Although it is less common, some Sanda practitioners have also fought in the publicly viewed American Mixed Martial Arts competitions.
					</p>
				</section>
			</Container>
		</Layout>
	)
}

const FormSection = styled.section`
	margin: 0 auto;
	display: grid;
	grid-template-columns: 2fr 3fr;
	justify-content: space-evenly;
	padding-bottom: 50px;
`

const Header = styled.h3`
	color: hsl(356, 45%, 48%);
`

const FormListContainer = styled.div`
	text-align: center;
	border: 1px solid rgba(0,0,0,.2);
	padding: 20px;
	padding-top: 30px;
`

const FormList  = styled.ul`
	list-style-type: none;
	font-size: 1.6em;
	margin: 0
`

const FormCategory = styled.div`
	text-align: center;
	font-size: 1.5em;
	padding: 10px;
	cursor: pointer;
	color: ${ ({ isActive }) => isActive ? 'hsl(356, 65%, 48%)' : '#aaa' };
	border-top: 1px dashed rgba(0,0,0,.2);
	:first-child {
		borderTop: 0;
	}
`

const query = graphql`
	query {
		four: file(relativePath: { eq: "skills/four.png" }) {
			childImageSharp {
				fluid(maxWidth: 638) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`