import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import Layout from '../components/Layout'
import Container from '../components/Container'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Layout>
			<ContortionContainer>
				<section>
					<Title>Shifu Edward Aguirre</Title>
					<RightwardImg
						fluid={ data.edward.childImageSharp.fluid }
						alt="Shifu Edward Aguirre"
					/>
					<p>
						Shifu Edward Aguirre is professionally trained in Shandong, Beijing and Shanghai China. Over 50 years dedication learning and 44 years teaching in NYC. An invited head coach in eight countries to advance competition levels since 1991.
					</p>
					<p>
						He founded and funded the first professional Wu Shu team in the USA in 1989 which competed in the NASKA circuit for 3 years. This made all other 
						martial arts that were part of that circuit to take Wu Shu serious. His dedication helped to introduce Wu Shu to the YMCA, city council of NYC and elementary to high school 
						in the boroughs of Manhattan and Queens. One of his favorite achievements was teaching Wu Shu to young kids at the United Nations International School.
					</p>
					<p>
						He has hosted one national competition in 2001 incorporating Wu Shu and Breakdancing. In 2002 he was inducted into the European Hall of Fame as Grandmaster of the Year. In 2003 
						co-hosted the 1st Caribbean Wu Shu Cup in San Juan Puerto Rico. In 2005, he hosted the World Wu Shu Kung Fu Championship in Miami Florida.
					</p>
					{/* <ViewResumeContainer>
						<ResumeAnchor
							href="https://drive.google.com/file/d/1vs-8yNOY7QHIelT4nGPBYC2vpEpC9SwY/view?usp=sharing"
							target="_blank"
							rel="noopener noreferrer">
							View resume
						</ResumeAnchor>
					</ViewResumeContainer> */}
				</section>
				<section>
					<Title>Uranmandakh Amarsanaa-Aguirre</Title>
					<LeftwardImg
						fluid={ data.urnaa.childImageSharp.fluid }
						alt="Head Coach Uranmandakh Amarsanaa-Aguirre"
					/>
					<p>
						Head coach Uranmandakh Amarsanaa-Aguirre was born in Ulaanbaatar Mongolia. At the age of 7 she began to study the art of contortion.
						Discipline and commitment led her to land showcases with the Mongolian National Circus “Level A” team and was able to travel to
						many countries including a dozen cities in the United States.
					</p>
					<p>
						One of her bigger achievements was landing a part in “Cirque Dreams” (an American Theatre company based in Florida). Her wish is
						to share the knowledge her teachers have passed on to her. Currently she is training in Wu Shu since February of 2012. Coach
						Amarsanaa is determined to start a young girls contortion program in NYC. She is very patient, skilled and offers the young kids
						in our Wu Shu program her warm and gentle demeanor.
					</p>
				</section>
				<section>
					<Title>Collette Aguirre</Title>
					<RightwardImg
						fluid={ data.collette.childImageSharp.fluid }
						alt="Collette Aguirre"
					/>
					<p>
						I've been practicing Wu Shu since I was five years old. I specialize in the foundation of Wu Shu and assist my father in 
						teaching children/beginners to intermediate level adults. Wu Shu has given me the confidence and drive to be the best I can.
						It has kept me physically fit and mentally sharp. Doing this has molded me into who I am today and I enjoy helping others achieve the same goals.
					</p>
				</section>
			</ContortionContainer>
		</Layout>
	)
}

const ContortionContainer = styled(Container)`
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 60px;
`

const Title = styled.h3`
	color: hsl(356, 45%, 48%)
`

const RightwardImg = styled(Img)`
	width: 30%;
	float: left;
	margin-right: 30px;
	border-radius: 3px;
	@media (max-width: 720px) {
		width: 50%;
	}
`

const LeftwardImg = styled(Img)`
	width: 30%;
	float: right;
	margin-left: 30px;
	border-radius: 3px;
	@media (max-width: 720px) {
		width: 50%;
	};
`

const ViewResumeContainer = styled.div`
	font-size: 1.5em;
`

const ResumeAnchor = styled.a`
	color: rgb(219, 51, 62);
	text-decoration: none;
	:visited {
		color: rgb(219, 51, 62);
	}
`

const query = graphql`
	query {
		edward: file(relativePath: { eq: "instructors/edward.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1365) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		urnaa: file(relativePath: { eq: "instructors/urnaa.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 710) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		collette: file(relativePath: { eq: "instructors/collette.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 710) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`
