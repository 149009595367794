import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import styled from 'styled-components'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Container>
			{/* <SocialMediaLink
				href="https://www.facebook.com/ditanwushucontortionnyc"
				target="_blank"
				rel="noopener noreferrer">
				<SocialMediaIcons fixed={ data.facebook.childImageSharp.fixed } />
			</SocialMediaLink> */}
			<SocialMediaLink
				href="https://www.instagram.com/ditan_official/"
				target="_blank"
				rel="noopener noreferrer">
				<SocialMediaIcons fixed={ data.instagram.childImageSharp.fixed } />
			</SocialMediaLink>
		</Container>
	)
}

const Container = styled.div`
	position: absolute;
	top: 20px;
	right: 10px;
	z-index: 1;
	@media (max-width: 1100px) {
		visibility: hidden;
		display: none;
	}
`
const SocialMediaLink = styled.a`
	display: block;
`
const SocialMediaIcons = styled(Img)`
	opacity: 0.5;
	:hover {
		opacity: 1.0;
	}
`

const query = graphql`
	query {
		facebook: file(relativePath: { eq: "social/facebook.png" }) {
			childImageSharp {
				fixed(width: 48, height: 48) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		},
		instagram: file(relativePath: { eq: "social/instagram.png" }) {
			childImageSharp {
				fixed(width: 48, height: 48) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		}
	}
`
